/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Peintures from 'views/Peintures';
import Ei from 'layouts/Ei';
import WithLayout from 'WithLayout';

const OeuvresPage = () => {
  return (
    <WithLayout
      component={Peintures}
      layout={Ei}
    />
  )
};

export default OeuvresPage;
